<template>
  <v-card>
    <v-card-title>
      <menu-title :title="'Create Topic'"></menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-text-field
            outlined
            v-model="tmpTopic.topictype"
            label="Type"
            hide-details
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-chip
            class="ma-1"
            v-for="topic in getTopics"
            :key="topic.id"
            color="primary"
            close
            variant="elevated"
            @click:close="deleteOneTopic(topic)"
          >
            {{ topic.topictype }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="$emit('done')" outlined>
        <span>Cancel</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="createNewTopic()" color="primary" outlined> Create </v-btn>
    </v-card-actions>
  </v-card>
</template>
    
    
    
<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../../../helpers/menu_title.vue";

export default {
  components: {
    menuTitle,
  },
  data: () => ({
    menu: false,
    tmpTopic: { topictype: "" },
  }),
  computed: {
    ...mapGetters({
      getTopics: "getTopics",
    }),
  },
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
      postTopic: "postTopic",
      deleteTopic: "deleteTopic",
    }),
    createNewTopic() {
      this.postTopic(this.tmpTopic).then((error) => {
        if (error) {
          this.showSnackbar(
            "Topic could not be posted. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Topic has been created");
          this.tmpTopic.topictype = "";
          this.$emit("done");
        }
      });
    },
    deleteOneTopic(topic) {
      this.deleteTopic(topic).then((error) => {
        if (error) {
          this.showSnackbar(
            "Topic could not be deleted. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Topic has been deleted");
        }
      });
    },
  },
};
</script>
    
<style scoped>
</style>