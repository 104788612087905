<template>
  <v-card flat>
    <v-card-title>
      <span style="color: #b31616; font-size: 1.5rem">Announcements</span>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="post"> Make Announcement </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container fluid fill-height>
        <v-row>
          <v-col cols="9">
            <v-text-field
              outlined
              hide-details
              label="Titel"
              v-model="tmpAnnouncement.announcementtitle"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Expiration date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.menu.save(date);
                    tmpAnnouncement.expirationdate = date;
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="tmpAnnouncement.topictype"
              item-text="topictype"
              item-value="id"
              :items="getTopics"
              label="Topics"
              outlined
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-btn outlined color="primary" @click="dialog = true">
              New Topic
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <div
              class="base-image-input"
              :style="{ 'background-image': `url(${imageData})` }"
              @click="chooseImage"
            >
              <span v-if="!imageData" class="placeholder">
                Choose an Image
              </span>
              <input
                class="file-input"
                ref="fileInput"
                type="file"
                @input="onSelectFile"
              />
            </div>
          </v-col>
          <v-col cols="5">
            <v-textarea
              v-model="tmpAnnouncement.announcementtext"
              outlined
            ></v-textarea>
          </v-col>
          <v-col cols="4">
            <div v-html="compiledMarkdown"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-dialog v-model="dialog" persistent>
      <topic-dialog @done="dialog = false"></topic-dialog>
    </v-dialog>
  </v-card>
</template>
  
  
  
  <script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../../../helpers/menu_title.vue";

import { marked } from "marked";
import topicDialog from "./topicdialog.vue";

export default {
  name: "AnnouncementEditor",
  components: {
    menuTitle,
    topicDialog,
  },
  data: () => ({
    markdown: "# hello",
    tmpAnnouncement: {},
    date: null, //(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
    menu: false,
    dialog: false,
    tmpTopic: { topictype: "" },
    imageData: null,
  }),
  computed: {
    ...mapGetters({
      getAnnouncement: "getAnnouncement",
      newAnnouncement: "newAnnouncement",
      getTopics: "getTopics",
    }),
    compiledMarkdown() {
      if (this.tmpAnnouncement.announcementtext) {
        return marked(this.tmpAnnouncement.announcementtext);
      } else {
        return "";
      }
    },
  },
  methods: {
    chooseImage() {
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },
    ...mapActions({
      fetchAnnouncementAsync: "fetchAnnouncementAsync",
      postAnnouncement: "postAnnouncement",
      showSnackbar: "showSnackbar",
      fetchTopics: "fetchTopics",
      deleteTopic: "deleteTopic",
    }),
    post() {
      this.tmpAnnouncement.file = this.$refs.fileInput.files[0];
      this.tmpAnnouncement.fileData = this.imageData;
      this.postAnnouncement(this.tmpAnnouncement).then((error) => {
        if (error) {
          this.showSnackbar(
            "Announcement could not be made. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("A new Announcement has been made");
          this.tmpAnnouncement = this.newAnnouncement;
        }
      });
    },
    deleteOneTopic(topic) {
      this.deleteTopic(topic).then((error) => {
        if (error) {
          this.showSnackbar(
            "Topic could not be deleted. Error {0}".format(error)
          );
        } else {
          this.showSnackbar("Topic has been deleted");
        }
      });
    },
  },
  async mounted() {
    await this.fetchAnnouncementAsync();
    this.fetchTopics();
    this.tmpAnnouncement = this.newAnnouncement;
  },
};
</script>
  
<style scoped>
.base-image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>